<template>
  <mobile-screen :header="true" screen-class="profile-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="profile-header-menu icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_profile-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("menu", "account", "account-my-profile") }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit" v-if="userDataCopy && isEditable">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <template v-if="hasData">
      <VeeForm
        id="profileUpdateForm"
        @submit="submitForm"
        v-slot="{ errors }"
        class="form"
        ref="profileUpdateForm"
        novalidate
      >
        <ul class="clebex-item-section">
          <!-- Photo -->
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="userFormFields.language"
          >
            <router-link
              :to="{ name: 'r_profile-change-photo' }"
              class="clebex-item-content-wrapper"
              v-if="isEditable"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label">{{
                  displayLabelName("profile", "profile", "profile-photo")
                }}</label>
              </div>
              <div class="profile-photo-header-wrapper">
                <div
                  class="photo-wrapper"
                  :style="{
                    'background-image': `url(${
                      previewPhoto ? previewPhoto : userPhoto
                    })`
                  }"
                >
                  <div
                    class="initials"
                    v-if="!previewPhoto && userInitials && !userPhoto"
                  >
                    {{ userInitials }}
                  </div>
                </div>
              </div>
            </router-link>
            <div v-else class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label">{{
                  displayLabelName("profile", "profile", "profile-photo")
                }}</label>
              </div>
              <div class="profile-photo-header-wrapper">
                <div
                  class="photo-wrapper"
                  :style="{
                    'background-image': `url(${
                      previewPhoto ? previewPhoto : userPhoto
                    })`
                  }"
                >
                  <div
                    class="initials"
                    v-if="!previewPhoto && userInitials && !userPhoto"
                  >
                    {{ userInitials }}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- First name -->
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.first_name }"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <span class="error-message" v-if="errors.first_name">
                  {{ errors.first_name }}
                </span>
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label class="clebex-section-input-label" for="firstNameId"
                      >{{
                        displayLabelName("profile", "profile", "first-name")
                      }}
                      <span>*</span></label
                    >
                    <Field
                      name="first_name"
                      as="input"
                      type="text"
                      rules="required"
                      id="firstNameId"
                      v-model="userDataCopy.first_name"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else>
              <span class="error-message" v-if="errors.first_name">
                {{ errors.first_name }}
              </span>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="firstNameId"
                    >{{ displayLabelName("profile", "profile", "first-name") }}
                    <span>*</span></label
                  >
                  <Field
                    name="first_name"
                    as="input"
                    type="text"
                    rules="required"
                    id="firstNameId"
                    v-model="userDataCopy.first_name"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
          <!-- Middle name -->
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="userFormFields.middleName"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label
                      class="clebex-section-input-label"
                      for="middleNameId"
                      >{{
                        displayLabelName("profile", "profile", "middle-name")
                      }}</label
                    >
                    <Field
                      name="middle_name"
                      as="input"
                      type="text"
                      id="middleNameId"
                      v-model="userDataCopy.middle_name"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label
                    class="clebex-section-input-label"
                    for="middleNameId"
                    >{{
                      displayLabelName("profile", "profile", "middle-name")
                    }}</label
                  >
                  <Field
                    name="middle_name"
                    as="input"
                    type="text"
                    id="middleNameId"
                    v-model="userDataCopy.middle_name"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
          <!-- Last name -->
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.last_name }"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <span class="error-message" v-if="errors.last_name">
                  {{ errors.last_name }}
                </span>
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label class="clebex-section-input-label" for="lastNameId"
                      >{{
                        displayLabelName("profile", "profile", "last-name")
                      }}
                      <span>*</span></label
                    >
                    <Field
                      name="last_name"
                      as="input"
                      type="text"
                      rules="required"
                      id="lastNameId"
                      v-model="userDataCopy.last_name"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else>
              <span class="error-message" v-if="errors.last_name">
                {{ errors.last_name }}
              </span>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="lastNameId"
                    >{{ displayLabelName("profile", "profile", "last-name") }}
                    <span>*</span></label
                  >
                  <Field
                    name="last_name"
                    as="input"
                    type="text"
                    rules="required"
                    id="lastNameId"
                    v-model="userDataCopy.last_name"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
          <!-- Email -->
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.email }"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <span class="error-message" v-if="errors.email">
                  {{ errors.email }}
                </span>
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label class="clebex-section-input-label" for="emailId"
                      >{{ displayLabelName("profile", "profile", "email") }}
                      <span>*</span></label
                    >
                    <Field
                      name="email"
                      as="input"
                      type="email"
                      rules="required|email"
                      id="emailId"
                      v-model="userDataCopy.email"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else>
              <span class="error-message" v-if="errors.email">
                {{ errors.email }}
              </span>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="emailId"
                    >{{ displayLabelName("profile", "profile", "email") }}
                    <span>*</span></label
                  >
                  <Field
                    name="email"
                    as="input"
                    type="email"
                    rules="required|email"
                    id="emailId"
                    v-model="userDataCopy.email"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
          <!-- City -->
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="userFormFields.city"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label class="clebex-section-input-label" for="cityId">{{
                      displayLabelName("profile", "profile", "city")
                    }}</label>
                    <Field
                      name="city"
                      as="input"
                      type="text"
                      id="cityId"
                      v-model="userDataCopy.city"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="cityId">{{
                    displayLabelName("profile", "profile", "city")
                  }}</label>
                  <Field
                    name="city"
                    as="input"
                    type="text"
                    id="cityId"
                    v-model="userDataCopy.city"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
          <!-- Company -->
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="userFormFields.company"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label class="clebex-section-input-label" for="companyId">{{
                      displayLabelName("profile", "profile", "company")
                    }}</label>
                    <Field
                      name="company"
                      as="input"
                      type="text"
                      id="companyId"
                      v-model="userDataCopy.company"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="companyId">{{
                    displayLabelName("profile", "profile", "company")
                  }}</label>
                  <Field
                    name="company"
                    as="input"
                    type="text"
                    id="companyId"
                    v-model="userDataCopy.company"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
          <!-- Department -->
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="userFormFields.department"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label
                      class="clebex-section-input-label"
                      for="departmentId"
                      >{{
                        displayLabelName("profile", "profile", "department")
                      }}</label
                    >
                    <Field
                      name="department"
                      as="input"
                      type="text"
                      id="departmentId"
                      v-model="userDataCopy.department"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label
                    class="clebex-section-input-label"
                    for="departmentId"
                    >{{
                      displayLabelName("profile", "profile", "department")
                    }}</label
                  >
                  <Field
                    name="department"
                    as="input"
                    type="text"
                    id="departmentId"
                    v-model="userDataCopy.department"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="userFormFields.costCode"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label
                      class="clebex-section-input-label"
                      for="costCodeId"
                      >{{
                        displayLabelName("profile", "profile", "cost-code")
                      }}</label
                    >
                    <Field
                      name="costCode"
                      as="input"
                      type="text"
                      id="costCodeId"
                      v-model="userDataCopy.cost_code"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else class="disabled">
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label
                    class="clebex-section-input-label"
                    for="projectCostCode"
                    >{{
                      displayLabelName("profile", "profile", "cost-code")
                    }}</label
                  >
                  <Field
                    name="costCode"
                    as="input"
                    type="text"
                    id="costCodeId"
                    v-model="userDataCopy.cost_code"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="userFormFields.projectCostCode"
          >
            <Popper
              v-if="userDataCopy.azure_external_id"
              hover
              class="popper-warning w-100"
              :content="displayLabelName('profile.profile.added-externally')"
            >
              <section class="disabled">
                <div class="clebex-item-content-wrapper">
                  <div class="clebex-section-input">
                    <label
                      class="clebex-section-input-label"
                      for="projectCostCodeId"
                      >{{
                        displayLabelName(
                          "profile",
                          "profile",
                          "project-cost-code"
                        )
                      }}</label
                    >
                    <Field
                      name="projectCostCode"
                      as="input"
                      type="text"
                      id="projectCostCode"
                      v-model="userDataCopy.project_cost_code"
                      :disabled="submitting || !isEditable"
                    />
                  </div>
                </div>
              </section>
            </Popper>
            <section v-else class="disabled">
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label
                    class="clebex-section-input-label"
                    for="projectCostCodeId"
                    >{{
                      displayLabelName(
                        "profile",
                        "profile",
                        "project-cost-code"
                      )
                    }}</label
                  >
                  <Field
                    name="projectCostCode"
                    as="input"
                    type="text"
                    id="projectCostCodeId"
                    v-model="userDataCopy.project_cost_code"
                    :disabled="submitting || !isEditable"
                  />
                </div>
              </div>
            </section>
          </li>
        </ul>
        <button
          type="submit"
          ref="submitProfileForm"
          style="display: none"
        ></button>
      </VeeForm>

      <ul class="clebex-item-section">
        <!---------------------------------->
        <!-- LANGUAGE - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.language"
        >
          <div
            @click="openLanguage(language, 'r_language')"
            class="clebex-item-content-wrapper"
            v-if="isEditable"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{ displayLabelName("profile", "profile", "language") }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ language ? language.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <div v-else class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("profile", "profile", "language")
            }}</label>
            <p class="text-label ellipsis">
              {{ language ? language.name : "" }}
            </p>
          </div>
        </li>
        <!---------------------------------->
        <!-- LANGUAGE - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- TIMEZONE - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.timezone"
        >
          <div
            v-if="isEditable"
            @click="openTimezone(timezone, 'r_time-zone')"
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{ displayLabelName("profile", "profile", "timezone") }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{
                  timezone
                    ? timezone.name
                        .replace(/ \*/g, ", ")
                        .replace("UTC ", "UTC+")
                    : ""
                }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <div v-else class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("profile", "profile", "timezone")
            }}</label>
            <p class="text-label ellipsis">
              {{
                timezone
                  ? timezone.name.replace(/ \*/g, ", ").replace("UTC ", "UTC+")
                  : ""
              }}
            </p>
          </div>
        </li>
        <!---------------------------------->
        <!-- TIMEZONE - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- COUNTRY - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.country"
        >
          <div
            @click="openCountry(country, 'r_profile-select-country')"
            v-if="isEditable"
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{ displayLabelName("profile", "profile", "country") }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ country ? country.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <div v-else class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("profile", "profile", "country")
            }}</label>
            <p class="text-label ellipsis">
              {{ country ? country.name : "" }}
            </p>
          </div>
        </li>
        <!---------------------------------->
        <!-- COUNTRY - END -->
        <!---------------------------------->
      </ul>

      <ul class="clebex-item-section">
        <!-- Password -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="
            !userDataCopy.sso && userFormFields.changePassword && isEditable
          "
        >
          <router-link
            :to="{ name: 'r_change-profile-password' }"
            class="clebex-item-content-wrapper"
            ><span class="label">{{
              displayLabelName("profile", "profile", "change-password")
            }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span> </span
          ></router-link>
        </li>
        <!-- Sso -->
        <li
          class="clebex-item-section-item"
          v-if="userFormFields.sso && isEditable"
        >
          <Popper
            v-if="userDataCopy.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <div class="clebex-item-content-wrapper">
                <dl class="clebex-item-dl justify-start">
                  <dt class="clebex-item-dt">
                    <div class="checkbox slide">
                      <input
                        id="userSsoCheckbox1"
                        type="checkbox"
                        :checked="hasData && userDataCopy.sso"
                        disabled
                      />
                      <label for="userSsoCheckbox1"></label>
                    </div>
                  </dt>
                  <dd class="clebex-item-dd justify-start">
                    {{ displayLabelName("profile", "profile", "sso") }}
                  </dd>
                </dl>
              </div>
            </section>
          </Popper>
          <section v-else>
            <div class="clebex-item-content-wrapper">
              <dl class="clebex-item-dl justify-start">
                <dt class="clebex-item-dt">
                  <div class="checkbox slide">
                    <input
                      id="userSsoCheckbox1"
                      type="checkbox"
                      :checked="hasData && userDataCopy.sso"
                      disabled
                    />
                    <label for="userSsoCheckbox1"></label>
                  </div>
                </dt>
                <dd class="clebex-item-dd justify-start">
                  {{ displayLabelName("profile", "profile", "sso") }}
                </dd>
              </dl>
            </div>
          </section>
        </li>
        <!-- Sync with outlook -->
        <li
          class="clebex-item-section-item"
          v-if="
            userFormFields.authorizeSynchronizationWithOutlook && isEditable
          "
        >
          <router-link
            :to="{ name: 'r_sync-with-outlook' }"
            class="clebex-item-content-wrapper"
          >
            <span class="label">
              {{
                displayLabelName(
                  "profile",
                  "profile",
                  "authorize-synchronization-with-outlook"
                )
              }}
            </span>

            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <!-- Sync with outlook -->
        <li
          class="clebex-item-section-item"
          v-if="userFormFields.authorizeSynchronizationWithGoogle && isEditable"
        >
          <router-link
            :to="{ name: 'r_sync-with-google' }"
            class="clebex-item-content-wrapper"
          >
            <span class="label">
              {{
                displayLabelName(
                  "profile",
                  "profile",
                  "authorize-synchronization-with-google"
                )
              }}
            </span>

            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <!-- User name to be searched -->
        <li
          class="clebex-item-section-item"
          v-if="
            hasSubdomain &&
              userFormFields.authoriseYourNameToBeSearched &&
              isEditable
          "
        >
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="userNameToBeSearched1"
                    type="checkbox"
                    v-model="searchableName"
                  />
                  <label for="userNameToBeSearched1"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "profile",
                    "profile",
                    "authorise-your-name-to-be-searched"
                  )
                }}
              </dd>
            </dl>
          </div>
        </li>
      </ul>

      <ul class="clebex-item-section">
        <!-- Assigned resources -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.assignedResources && isEditable"
        >
          <router-link
            :to="{ name: 'r_profile-assigned-resources' }"
            class="clebex-item-content-wrapper"
            ><span class="label">{{
              displayLabelName("profile", "profile", "assigned-resources")
            }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span> </span
          ></router-link>
        </li>
      </ul>

      <ul class="clebex-item-section">
        <!-- Delegates -->
        <li
          v-if="userFormFields.myDelegates && isEditable"
          class="clebex-item-section-item full-right-underline"
        >
          <router-link
            :to="{ name: 'r_profile-my-delegates' }"
            class="clebex-item-content-wrapper"
            ><span class="label">{{
              displayLabelName("profile.profile.my-delegates")
            }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span> </span
          ></router-link>
        </li>
        <li
          v-if="userFormFields.delegatedBy"
          class="clebex-item-section-item full-right-underline"
        >
          <router-link
            :to="{ name: 'r_profile-delegated-by' }"
            class="clebex-item-content-wrapper"
            ><span class="label">{{
              displayLabelName("profile.profile.delegated-by")
            }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span> </span
          ></router-link>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="phoneId"
                >{{
                  displayLabelName("manage-users", "edit-user", "login-history")
                }}
                <span></span
              ></label>
              <div
                v-if="userDataCopy.access_logs.length > 0"
                style="display: flex; flex-direction: column;"
              >
                <span
                  style="background-color: transparent; box-shadow: none; color: #999999; font-size: 1rem; font-weight: 400; height: 1.4375rem; letter-spacing: -0.02562rem; line-height: 1.375rem; padding: 0 1rem; width: 100%; font-family: Roboto;"
                  v-for="access_log in userDataCopy.access_logs"
                  :key="access_log.id"
                >
                  {{ access_log.datetime }}</span
                >
              </div>
              <div v-else>
                <span
                  style="background-color: transparent; box-shadow: none; color: #999999; font-size: 1rem; font-weight: 400; height: 1.4375rem; letter-spacing: -0.02562rem; line-height: 1.375rem; padding: 0 1rem; width: 100%; font-family: Roboto;"
                  >{{
                    displayLabelName(
                      "manage-users",
                      "edit-user",
                      "user-did-not-yet-log-in"
                    )
                  }}</span
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import HomeButton from "@/components/global/HomeButton";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import httpServiceAuth, { setLangId, setLang } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { getSubdomain, getUserId } from "@/services/http-service";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import { errorHandler } from "@/services/error-handler";
import { preferenceCategoryIds } from "@/services/preference-options";
import Popper from "vue3-popper";

export default {
  name: "Profile",
  data() {
    return {
      id: getUserId(),
      submitting: false,
      reload: false,
      userDataCopy: null,
      searchableName: false,
      previewPhoto: null,
      fileToUpload: null,
      userFormTemplate: preferenceCategoryIds.userFormTemplate,
      editing: true,
      helpSlug: "profile"
    };
  },
  created() {
    this.setSelectedLanguage(null);
    this.setSelectedTimezone(null);
    this.setSelectedCountry(null);
    this.getUserFormFields(this.userFormTemplate);
  },
  watch: {
    userData: {
      immediate: true,
      handler() {
        if (this.userData && this.userData.data) {
          this.userDataCopy = { ...this.userData.data };
          this.searchableName = Boolean(this.userData.data.searchable_name);
        }
      }
    },
    selectedLanguage(oldValue, newValue) {
      if ((newValue != null) & (oldValue != newValue)) {
        this.initSubmit();
      }
    },
    selectedTimezone(oldValue, newValue) {
      if ((newValue != null) & (oldValue != newValue)) {
        this.initSubmit();
      }
    },
    selectedCountry(oldValue, newValue) {
      if ((newValue != null) & (oldValue != newValue)) {
        this.initSubmit();
      }
    },
    isOutlookSynced(val) {
      if (typeof val == "boolean") {
        this.initSubmit();
      }
    },
    searchableName() {
      this.initSubmit();
    }
  },
  computed: {
    ...mapState("user", [
      "userData",
      "company",
      "outlookSyncUrl",
      "isOutlookSynced",
      "userFormFields"
    ]),
    ...mapGetters("user", ["userPhoto"]),
    isEditable() {
      return this.canItem(this.userDataCopy, "update");
    },
    hasData() {
      return !!this.userDataCopy;
    },
    hasSso() {
      const { userData } = this;
      if (userData && userData.data) {
        return Boolean(userData.data.sso);
      }
      return null;
    },
    hasSubdomain() {
      return getSubdomain();
    },
    userInitials() {
      if (this.hasData) {
        return (
          this.userData.data.first_name[0] + this.userData.data.last_name[0]
        );
      }
      return null;
    },
    language() {
      if (this.selectedLanguage) {
        return this.selectedLanguage;
      } else {
        return this.getLanguage(this.userData.data.language_id);
      }
    },
    timezone() {
      if (this.selectedTimezone) {
        return this.selectedTimezone;
      } else {
        return this.getTimezone(this.userData.data.timezone_id);
      }
    },
    country() {
      if (this.selectedCountry) {
        return this.selectedCountry;
      } else {
        return this.getCountry(this.userData.data.country_id);
      }
    }
  },
  methods: {
    ...mapActions("user", [
      "getMasterUserData",
      "getCompanyUserData",
      "getUserFormFields"
    ]),
    ...mapActions("country", ["setSelectedCountry", "getCountries"]),
    ...mapActions("language", ["setSelectedLanguage"]),
    ...mapActions("timezone", ["setSelectedTimezone"]),
    ...mapActions("translation", ["getTranslations"]),
    submitForm(values) {
      if (values) {
        const subdomain = getSubdomain();

        values.sso = Number(this.hasSso);
        values.searchable_name = Number(this.searchableName);
        values.is_authorized_with_outlook = Number(this.isOutlookSynced);

        this.$store.dispatch("user/setOutlookSynced", "updated", {
          root: true
        });

        let language_id = this.language.id;
        let timezone_id = this.timezone.id;
        let country_id = this.country.id;

        const data = {
          ...this.userData.data,
          ...values
        };

        if (language_id) {
          if (language_id !== data.language_id) {
            setLangId(language_id);
            setLang(this.selectedLanguage.code);
            data.language_id = language_id;

            // this.reload = true;
            // this.$router.go(0);
            this.getTranslations();
          }
        }

        if (timezone_id && !this.unsetTimezone) {
          data.timezone_id = timezone_id;
        }

        if (this.unsetTimezone) {
          data.timezone_id = null;
        }

        if (country_id) {
          data.country_id = country_id;
        }

        this.submitting = true;
        if (subdomain) {
          this.submitCompanyData(data);
        } else {
          this.submitMasterData(data);
        }
      }
    },
    submitMasterData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(`${apiEndpoints.master.users}/${this.id}`, values)
        .then(() => {
          this.getMasterUserData(this.id);
          if (this.reload) {
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(`${apiEndpoints.company.users}/${this.id}`, values)
        .then(() => {
          this.getCompanyUserData(this.id);
          if (this.reload) {
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          if (this.fileToUpload) {
            this.uploadPhoto();
          }
        });
    },
    initSubmit() {
      const { submitProfileForm } = this.$refs;
      if (submitProfileForm) {
        submitProfileForm.click();
      }
    },
    uploadPhoto() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const formData = new FormData();
      formData.append("image", this.fileToUpload);
      httpServiceAuth
        .post(apiEndpoints.company.userProfileImage, formData, {
          headers: { "content-type": "multipart/form-data" }
        })
        .then(response => {
          this.$store.commit("user/setUserProfilePhoto", response.data, {
            root: true
          });
          this.cancelPhotoUpload();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    cancelPhotoUpload() {
      this.previewPhoto = null;
      this.fileToUpload = null;
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    HomeButton,
    Popper
  },
  mixins: [languagesMixin, timezonesMixin, countriesMixin, helpOnlineMixin]
};
</script>
